import { useStaticQuery, graphql } from 'gatsby'

export function getMonthYearFromDate(date) {
  return `${date.getMonth()} ${date.getYear()}`
}
export function toMonthYearString(date) {
  const d = date.toDateString()
  return d.slice(4, 7) + ' ' + d.slice(10)
}

export function useSiteMetaData() {
  const {
    site: { siteMetadata },
  } = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
          description
          author
          siteURL
        }
      }
    }
  `)

  return siteMetadata
}
